


















































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
	data: () => ({}),
	computed: {
		...mapGetters({ isAuthenticated: 'auth/isAuthenticated' }),
	},
	methods: {
		...mapActions({ signOut: 'auth/signOut', testError: 'testError' }),
	},
});
