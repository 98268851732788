













import Vue from 'vue';
import Signin from '../components/Signin.vue';
import Signup from '../components/Signup.vue';

export default Vue.extend({
	components: {
		Signin,
		Signup,
	},
});
