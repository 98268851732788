






































import Vue from 'vue';
import { mapActions } from 'vuex';
export default Vue.extend({
	name: 'Signup',
	data: () => ({
		valid: true,
		busy: false,
		email: '',
		emailRules: [
			(v: string) => !!v || 'E-mail is required',
			(v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
		],
		password: '',
		passwordRules: [
			(v: string) => !!v || 'Password is required',
			(v: string) => (v && v.length >= 8) || 'Password must be at least 8 characters',
		],
		showPassword: false,
	}),
	computed: {
		form(): Vue & { validate: () => boolean; reset: () => void } {
			return this.$refs.form as Vue & {
				validate: () => boolean;
				reset: () => void;
			};
		},
	},
	methods: {
		...mapActions({ authSignUp: 'auth/signUp' }),
		signUp() {
			this.busy = true;
			this.authSignUp({
				email: this.email,
				password: this.password,
			}).then((response: any) => {
				this.form.reset();
				this.busy = false;
				if (!!response) this.$router.replace(<string>this.$route.query.r || '/');
			});
		},
		validate() {
			this.form.validate();
		},
		reset() {
			this.form.reset();
		},
	},
});
