






















import Vue from 'vue';
import { mapActions } from 'vuex';
export default Vue.extend({
	data: () => ({ file: null, successMessage: '' }),
	computed: {
		success: {
			get: function () {
				return !!this.successMessage;
			},
			set: function (val) {
				if (!val) this.successMessage = '';
			},
		},
	},
	watch: {
		file: function (file) {
			console.log(file);
		},
	},
	methods: {
		...mapActions({ addFile: 'file/add' }),
		onUploadClick: function () {
			this.addFile(this.file);
			this.file = null;
			this.successMessage = 'File uploaded successfully!';
		},
	},
});
