<template>
  <center>
    <h1 class="display-4 ma-10">OOPS!</h1>
    <p>Looks like we don't have that here.</p>
    <p>
      <router-link to="/">Go Home</router-link>
    </p>
  </center>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
