











































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import VuetifyLogo from '../../../web/components/VuetifyLogo.vue';
import firebase from 'firebase';

export default Vue.extend({
	name: 'Home',
	components: { VuetifyLogo },
	mounted() {
		this.$store.dispatch('file/load');
		//this.hideLoading(2000);
	},
	data: () => ({
		//loading: true,
	}),
	computed: {
		...mapGetters({ myFiles: 'file/myFiles', loading: 'file/loading' }),
		empty(): boolean {
			return !this.myFiles.length;
		},
	},
	methods: {
		...mapActions({
			deleteFile: 'file/delete',
			reload: 'file/load',
			download: 'file/download',
		}),
		onDeleteClick: function (fileName: string) {
			console.log('delete clicked: ' + fileName);
			this.deleteFile(fileName);
		},
		onDownloadClick: async function (fileName: string) {
			var match: firebase.storage.Reference[] = this.myFiles.filter(
				(f: firebase.storage.Reference) => f.fullPath == fileName,
			);
			if (!match.length) return;
			let url = await match[0].getDownloadURL();
			let data = await this.download(url);
			var fileURL = window.URL.createObjectURL(data);
			var fileLink = document.createElement('a');
			fileLink.href = fileURL;
			fileLink.setAttribute('download', fileName);
			document.body.appendChild(fileLink);
			fileLink.click();
		},
		hideLoading: function (timeout: number | undefined) {
			let _this = this;
			if (timeout)
				setTimeout(function () {
					_this.loading = false;
				}, timeout);
			else _this.loading = false;
		},
	},
});
