










import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
export default Vue.extend({
	data: () => ({}),
	methods: {
		...mapActions(['clearError']),
	},
	computed: {
		...mapGetters(['getError']),
	},
});
